<template>
    <div class="tokenomics-header">
        <h2>TOKENOMICS</h2>
        <div class="key-features-wrap">
            <div class="button-key" style="display:none;">
                <p>KEY FEATURES</p>
            </div>
        </div>
        <div class="accent-wrap"></div>
        <div class="accent-end"><img src="bg/barcode-accent.png" alt=""></div>
    </div>
    <div class="tokenomics-main">
        <div class="accent-text-row">
            <p>Initializing Tokenomics Dashboard</p>
            <p>Syncing: 45%</p>
            <p>Initialization Complete</p>
        </div>
        <div class="image-text-row-token">


            <div class="token-data-box-wrapper">
                <div class="token-data-box">
                    <h3>193.19B</h3>
                    <p>CIRC. SUPPLY</p>
                    <img src="/bg/tokenback.png" alt="">
                </div>
                <div class="token-data-box">
                    <h3>0%</h3>
                    <p>BUY/SELL TAX</p> <img src="/bg/tokenback.png" alt="">

                </div>

                <div class="token-data-box">
                    <h3>369B</h3>
                    <p>TOTAL SUPPLY</p> <img src="/bg/tokenback.png" alt="">

                </div>
                <div class="token-data-box">
                    <h3>$ROKO</h3>
                    <p>TICKER</p> <img src="/bg/tokenback.png" alt="">

                </div>
            </div>

            <div class="accent-token">// 03</div>

            <div class="image-wrapper-token">
                <img src="bg/tkbg.png" alt="">
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'WhatIs',
};
</script>
  
<style lang="scss" scoped>
.token-data-box-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 2rem;
    width: 600px;
    height: fit-content;
    margin: auto;

    .token-data-box {
        width: 280px;
        height: 180px;
        background-image: url(/bg/tokenback.png);
        background-position: center;
        background-repeat: no-repeat;

        p {
            text-align: center;
            color: white;margin:unset;
        }

        h3 {
            text-align: center;
            font-size: 3rem;
            font-weight: 400;
            margin-top: 4rem;margin-bottom:0.7rem;
        }

        img {
            display: none;
        }
    }
}

.tokenomics-main {
    position: relative;

    .accent-text-row {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        padding: 2rem;
        font-size: 8px;
        text-transform: uppercase;

    }

    .image-text-row-token {
        padding: 2rem;
        display: flex;
        flex-flow: row;
        gap: 4rem;
        position: relative;

        .accent-token {
            position: absolute;
            right: 200px;
            top: 100px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 10px;

            &::before {
                display: none;
                content: '';
                height: 1px;
                background: black;
                width: 11px;
                position: absolute;
                left: -20px;
                top: -11px;
            }

            &::after {
                display: none;

                content: '';
                width: 1px;
                background: black;
                height: 11px;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }

        .image-wrapper-token {
            width: 50%;

            img {}
        }

        .text-wrapper-token {
            display: flex;
            flex-flow: column;
            width: 50%;
            height: fit-content;
            position: relative;

            .text-top {
                height: fit-content;
                position: relative;

                p {
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }

                .text-token-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;

                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }

            .text-bottom {
                height: fit-content;
                position: relative;

                p {
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }

                .text-token-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;

                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }
        }
    }
}

.tokenomics-header {
    border-bottom: 1px solid #C1C1C1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0rem 2rem;
    margin-top:10rem;

    h2 {
        font-size: 70px;
        font-weight: 400;
        margin: unset;
    }

    .key-features-wrap {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        justify-content: flex-end;

        .button-key {
            cursor: pointer;
            border: unset;
            width: 120px;
            padding: 0.5rem 1rem;
            font-family: "Rajdhani", sans-serif;
            background: black;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: 600;
            gap: 10px;
            transition: all 0.2s ease;

            /* Space between text and icon */
            /* Add other button styles */
            &:hover {
                filter: invert(0.9);
            }

            p {
                margin: unset;
            }
        }
    }

    .accent-end {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 2rem;
    }
}

.info-field {
    height: 50vh;
    position: relative;
    display: flex;margin-top:8rem;margin-left:2rem;

    .info-block {
        position: absolute;
        right: 20%;
        top: 40%;

        &::before {
            content: '';
            position: absolute;
            right: -15px;
            top: -10px;
            height: 11px;
            width: 1px;
            background: black;
        }

        &::after {
            content: '';
            position: absolute;
            right: -20px;
            top: -5px;
            height: 1px;
            width: 11px;
            background: black;
        }
    }
}

@media (max-width: 1200px) {
    .tokenomics-row {
        flex-flow: column;
    }
.accent-token{display:none;}
    .tokenomics-header {
        border-bottom: 1px solid #C1C1C1;
        display: flex;
        flex-flow: row;
        margin-top: 5rem;
        justify-content: space-between;
        padding: 0rem 2rem;

        h2 {
            font-size: 10vw;
            font-weight: 400;
            margin: unset;
        }
    }

    .tokenomics-main {
        .image-text-row-token {
            flex-flow: column;

            .image-wrapper-token {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .token-data-box-wrapper {
            width: 100%;
        }

    }
    .accent-end{display:none!important;}


}</style>
  